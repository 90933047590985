<template>
  <div>
    <b-row>
      <b-col sm="12">
        <iq-card class="iq-card">
          <div class="iq-card-body profile-page p-0 profilePages">
            <div class="profile-header">
              <div class="cover-container" style="overflow:hidden;">
                <img v-if="vmCoverPic" :src="vmCoverPic" class="rounded img-fluid"
                  style="object-fit: contain; width: 100%;">
                <img v-else :src="vmBannerImage" class="rounded img-fluid">
              </div>
              <div class="user-detail text-center mb-3">
                <div class="profile-img">
                  <img v-if="vmProfilePic" :src="vmProfilePic" alt="profile-img"
                    class="img-fluid rounded-circle moduleLogo" />
                  <b-avatar v-else variant="primary" class="avatar-100 rounded"
                    :text="getFirstLetterOfAString(itemName)" style="border-radius: 100%!important;">
                  </b-avatar>
                </div>
                <div class="profile-detail">
                  <h3 class="">
                    {{ itemName }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </iq-card>
      </b-col>

      <div class="col-lg-8">
        <div class="iq-card iq-card-block iq-card-stretch blog-post">
          <div class="iq-card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="iq-card-title">
                Current Event
              </h4>
            </div>
          </div>
          <div class="iq-card-body" v-if="vmEventlist && vmEventlist.length > 0">
            <ul class="list-inline p-0 mb-0 mt-2">
              <li class="mb-3" v-for="(eve, index) in vmEventlist" :key="index + 'LIST'">
                <div class="d-flex align-items-top pb-3 border-bottom">
                  <div class="col-md-7">
                    <div class="blog-description pl-2">
                      <h3 class="mb-2">
                        {{ eve.event_name }}
                      </h3>
                      <div class="date mb-1">
                        <a @click="goToEVE(eve)" style="cursor: pointer;" tabindex="-1">{{ eve.event_start_date |
                            dateFormatMMDOYYYY
                        }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="iq-card-body" v-else>No events Going On...</div>
          <b-row>
              <b-container fluid class="user_public_page">
                <b-row>
                  <b-col lg="12" class="userpublic_add_form">
                    <iq-card>
                      <template v-slot:body>
                        <ValidationObserver ref="pbaForm">
                          <form action="#" v-if="vmEventlist && vmEventlist.length">
                            <div v-if="!userData || (userData && (!Object.keys(userData).length > 0 || userData.user_role !=='USERROLE11114'))">
                            <div class="form-row" >
                              <div class="col-lg-8 mb-3">
                                <input class="form-control" v-model="search" placeholder="Search User Using Mobile Number Or Email" />
                              </div>
                              <div class="col-md-4 pt-1 pl-0">
                                <b-button type="button" variant="primary" @click="searchEventUser()">{{cvSearchBtn}}</b-button>
                              </div>
                              <hr class="w-100"/>
                            </div>

                            <div class="form-row" >
                              <div class="col-md-8 mb-3">
                                <label for="vmFormData.user_name">
                                  {{ cvName }}</label>
                                <ValidationProvider name="Please Enter your name" rules="required" v-slot="{ errors }">
                                  <input v-model="vmFormData.user_name" type="text" class="form-control" required />
                                  <span class="text-danger"> {{ errors[0] }} </span>
                                </ValidationProvider>
                              </div>
                              <div class="col-md-8 mb-3">
                                <label for="vmFormData.user_email">
                                  {{ cvEmail }}</label>
                                <ValidationProvider name="Please Enter your Email" rules="required" v-slot="{ errors }">
                                  <input v-model="vmFormData.user_email" type="text" class="form-control" required />
                                  <span class="text-danger"> {{ errors[0] }} </span>
                                </ValidationProvider>
                              </div>
                              <div class="col-md-8 mb-3">
                                <label for="vmFormData.user_name">
                                  {{ cvMobile }}</label>
                                <ValidationProvider name="Please Enter your cvMobile" rules="required"
                                  v-slot="{ errors }">
                                  <input v-model="vmFormData.user_mobile" type="text" class="form-control" required />
                                  <span class="text-danger"> {{ errors[0] }} </span>
                                </ValidationProvider>
                              </div>
                            </div>
                          </div>
                            <div class="form-group">
                              <button v-if="cvLoadingStatus" type="button" class="btn btn-primary"
                                :disabled="cvLoadingStatus">
                                <b-spinner label="Spinning"></b-spinner>
                              </button>
                              <div >
                              <b-button type="button" variant="primary" class="btncenter"  @click="submitAdd()">
                                {{ cvSubmitBtn }}
                              </b-button>
                              </div>
                            </div>
                          </form>
                        </ValidationObserver>
                      </template>
                    </iq-card>
                  </b-col>
                </b-row>
              </b-container>
            </b-row>
        </div>
      </div>
      <div class="col-lg-4 gide_right_mobile_data" v-if="gide_right_mobile_data">
        <div class="iq-card iq-card-block iq-card-stretch blog-post">
          <div class="iq-card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="iq-card-title">
                Upcoming Events
              </h4>
            </div>
          </div>
          <div class="iq-card-body" v-if="eveListObj && eveListObj.length > 0">
            <ul class="list-inline p-0 mb-0 mt-2">
              <li class="mb-3" v-for="(eve, index) in eveListObj" :key="index + 'LIST'">
                <div class="d-flex align-items-top pb-3 border-bottom">
                  <div class="col-md-7">
                    <div class="blog-description pl-2">
                      <h3 class="mb-2">
                        {{ eve.event_name }}
                      </h3>
                      <div class="date mb-1">
                        <a @click="goToEVE(eve)" style="cursor: pointer;" tabindex="-1">{{ eve.event_start_date |
                            dateFormatMMDOYYYY
                        }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="iq-card-body" v-else>New events coming soon...</div>
        </div>
      </div>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </div>
</template>

<style lang="scss" scoped>
@import url("../../assets/css/custom.css");
@import url("../../assets/css/PriceSlider.css");

.btncenter {
  margin-left: 295px
}
.user_public_page {
  .text-danger {
    color: #dc3545 !important;
  }

  .sign-info {
    border: 0px;
  }

  .user_public_subhead {
    color: var(--iq-body-text);
    font-size: 14px;
  }

  .form-control {
    height: 45px;
    line-height: 45px;
    background: transparent;
    border: 1px solid #f1f1f1;
    font-size: 14px;
    border-radius: 10px;
  }

  .userpublic_add_form::-webkit-scrollbar {
    display: none;
  }

  .userpublic_add_form {
    height: calc(100vh - 200px);
    margin: auto;
    overflow-y: scroll;
    padding: 0px;
    overflow-x: hidden;
    scrollbar-width: none;

    .table thead th {
      border-bottom: 0px;
    }

    .card-title {
      color: var(--iq-title-text);
    }

    label {
      color: var(--iq-body-text)
    }

    .iq-card {
      margin: 0px;
      border: none;
      box-shadow: none;

      .iq-card-header {
        padding: 0px;
        border: 0px;
      }

      .iq-card-body {
        padding: 0px;
      }
    }
  }
}

.moduleLogo {
  object-fit: contain;
  width: 100px;
  height: 100px !important;
  background: white;
}
</style>

<script>
import { socialvue } from "../../config/pluginInit.js"
import { Organisations } from "../../FackApi/api/organisation.js"
import ApiResponse from "../../Utils/apiResponse.js"
import Utility from "../../Utils/utility.js"
import addImage from "../../assets_gide/img/img/addPic.webp"
import { events } from "../../FackApi/api/events"
import UserRoles from "../../FackApi/json/UserRoles.json"
import moment from "moment"

export default {
  name: "Profile",
  components: {
  },
  data () {
    return {
      module_id: "",
      module_name: null,
      photos: [],
      profileImages: [],
      itemName: "",
      vmProfilePic: "",
      vmCoverPic: "",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Events Response",
      vmBannerImage: "",
      addImage: addImage,
      eveListObj: "",
      vmEventlist: "",
      search: "",
      vmFormData: {},
      cvName: "Enter Your Name",
      cvEmail: "Enter Your Email",
      cvMobile: "Enter Your Mobile Number",
      cvHostingPlatform: "event_hosting_platform",
      cvSubmitBtn: "Check in",
      cvLoadingStatus: false,
      gide_right_mobile_data: true,
      cvSearchBtn: "Search"
    }
  },
  mounted () {
    this.loadRequiredData()
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  methods: {
    /**
     * submitAdd
     */
    async submitAdd () {
      let valid = await this.$refs.pbaForm.validate()
      if (!valid) {
        return
      }

      this.vmFormData.event_id = this.vmEventlist[0].event_id
      this.vmFormData.event_hosting_platform = this.vmEventlist[0].event_hosting_platform
      this.vmFormData.module_obj_id = this.module_id // Alway set it in the module_id so that we can re-use it for different modules
      this.vmFormData.event_meeting_id = this.vmEventlist[0].event_weblink
      this.vmFormData.duration = this.vmEventlist[0].event_duration

      if (this.userData) {
        this.vmFormData.module_name = UserRoles[this.userData.user_role]
      }

      if (this.userData && this.userData.user_role === "USERROLE11114") {
        this.vmFormData.user_id = this.userData.user_id
        this.vmFormData.user_name = this.userData.user_name
        this.vmFormData.user_email = this.userData.user_email
        this.vmFormData.user_mobile = this.userData.user_mobile
      }
      else {
        // Use the userdata from the form directly;
        this.vmFormData.module_name = UserRoles["USERROLE11114"]
      }

      try {
        this.cvLoadingStatus = true

        let submittedDataResp = await events.eventUserBoothCheckin(this, this.vmFormData)
        if (submittedDataResp.resp_status) {
          ApiResponse.responseMessageDisplay(this, submittedDataResp)
          this.$refs.pbaForm.reset()
          this.vmFormData = {}
        }
        else {
          this.toastMsg = submittedDataResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
          this.$refs.pbaForm.reset()
          this.vmFormData = {}
        }
      }
      catch (err) {
        console.error("Exception occurred at gideUniversityPublicPageSubmit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * searchEventUser
     */
    async searchEventUser () {
      try {
        let filter = {}
        filter.search = this.search
        let eventUserList = await events.eventSearchUser(this, filter)
        if (eventUserList.resp_status) {
          let searchListObj = eventUserList.resp_data
          this.totalRows = eventUserList.resp_data.count
          this.vmFormData = searchListObj
        }
        else {
          this.toastMsg = eventUserList.resp_msg
          this.$refs.pbaForm.reset()
          this.vmFormData = []
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at searchEventUser() and Exception:", err.message)
      }
    },
    /**
       * getFirstLetterOfAString
       */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
       * loadRequiredData
       */
    loadRequiredData () {
      socialvue.index()
      this.module_id = this.$route.params.module_id

      if (this.userData && this.userData.user_role === "USERROLE11116") {
        // org
        // this.orgView()
        // this.orgViewUpcomingEventList()
        console.log("TO BE IMPLEMENTED")
      }
      else {
        // Org
        this.organisationView()
        this.organisationViewUpcomingEventList()
      }
    },
    /**
     * organisationViewUpcomingEventList for public page
     */
    async organisationViewUpcomingEventList () {
      try {
        let filter = {}
        filter.org_id = this.module_id

        let EventListResp = await events.organisationViewUpcomingEventList(this, filter)
        if (EventListResp.resp_status) {
          let totalEventsItem = EventListResp.resp_data.data
          this.eveListObj = EventListResp.resp_data.data
          let currentArrForEvent = []
          let upcomingArrForEvent = []
          this.totalRows = EventListResp.resp_data.count
          const currentTs = parseInt(moment().utc().valueOf() / 1000)
          for (let i = 0; i < totalEventsItem.length; i++) {
            totalEventsItem[i].event_start_ts = moment(totalEventsItem[i].event_start_ts * 1000).utc().subtract(1, "hour").valueOf() / 1000 // Decrease 1hr so org can see before 1hr
            if (currentTs >= totalEventsItem[i].event_start_ts && currentTs <= totalEventsItem[i].event_end_ts) {
              currentArrForEvent.push(totalEventsItem[i])
            }
            else {
              upcomingArrForEvent.push(totalEventsItem[i])
            }
          }
          this.eveListObj = upcomingArrForEvent
          this.vmEventlist = currentArrForEvent
        }
        else {
          this.toastMsg = EventListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at organisationViewUpcomingEventList() and Exception:", err.message)
      }
    },
    /**
       * organisationList
       */
    async organisationView () {
      try {
        let orgViewResp = await Organisations.organisationViewPublic(this, this.module_id)
        if (orgViewResp && orgViewResp.resp_status) {
          let orgObj = orgViewResp.resp_data.data

          this.itemName = orgObj.org_name

          this.vmBannerImage = Utility.getBgImageForLetter(this, this.itemName)
          if (orgViewResp.resp_data.imageProfileResp && !orgViewResp.resp_data.imageProfileResp.resp_status) {
            this.vmProfilePic = orgViewResp.resp_data.imageProfileResp.profile_pic ? orgViewResp.resp_data.imageProfileResp.profile_pic.image_thumbnail : ""
            this.vmCoverPic = orgViewResp.resp_data.imageProfileResp.cover_pic ? orgViewResp.resp_data.imageProfileResp.cover_pic.image : ""
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at organisationView Public Content() and Exception:", err.message)
      }
    }
  }
}
</script>
